import React, {Component} from 'react'
import Container from 'react-bootstrap/Container'
import './AttachedImages.scss'

class AttachedImages extends Component {
    constructor(props) {
        super(props)
        this.state = {images: props.images, triggerText: props.triggerText, showImages: props.triggerText ? false : true}
        this.toggleImages = this.toggleImages.bind(this)
    }

    toggleImages() {
        this.setState({showImages: !this.state.showImages})
    }

    render() {
        return <div id="attachedImages">
            <Container>
                {this.state.triggerText ? <div className="trigger-text" onClick={this.toggleImages}>{this.state.triggerText}</div>:''}
               {this.state.showImages ?  <div className="image-container">
                    {this.state.images.map((image, index) => <div className="image" key={'attachedimages-image-' + index}><img src={image} alt={'attachedimages-image-' + index}/></div>)}
                </div>: ''}
            </Container>
        </div>
    }
}

export default AttachedImages