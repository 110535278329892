import React, { Component } from "react";
import "./Item.scss";
import { connect } from "react-redux";
import { setSuggestion } from "../../../../../../action/wireSelection";

class Item extends Component {
  constructor(props) {
    super(props);
    this.select = this.select.bind(this);
  }

  select() {
    this.props.setSuggestion(this.props.suggestion);
    this.props.done();
  }

  render() {
    const rating = this.props.suggestion.rating;
    return (
      <div className="suggestion-table-item">
        <div className="attribute">{rating.wireAmount}</div>
        <div className="attribute">{rating.overlap14 / 2}</div>
        {this.props.combinationType === "known" && (
          <div className="attribute">{rating.overlap12 / 2}</div>
        )}
        <div className="attribute">{rating.overlap09 / 2}</div>
        <div className="attribute">
          {(rating.overlap14 + rating.overlap12 + rating.overlap09) / 2}
        </div>
        <div className="select" onClick={this.select}>
          auswählen
        </div>
      </div>
    );
  }
}

export default connect(null, { setSuggestion })(Item);
