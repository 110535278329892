import React, { Component } from "react";
import "./Item.scss";
import { connect } from "react-redux";
import { removeFromCart, editCartItem } from "../../../../action/cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

class Item extends Component {
  constructor(props) {
    super(props);
    this.removeItem = this.removeItem.bind(this);
    this.editItem = this.editItem.bind(this);
  }

  removeItem() {
    this.props.removeFromCart(this.props.index);
  }

  editItem() {
    this.props.editCartItem(this.props.index);
  }

  render() {
    const data = this.props.data;
    let suggestion = "";
    if (
      data.wireSelection.suggestion &&
      Object.keys(data.wireSelection.suggestion).length > 0 &&
      data.wireSelection.suggestion.wires.length > 0
    ) {
      suggestion = data.wireSelection.suggestion.wires.reduce(
        (acc, wire, i) => {
          let wireAmount =
            data.wireSelection.suggestion.rating.mainWireAmount +
            data.wireSelection.suggestion.rating.secondaryWireAmount;
          if (data.wireSelection.suggestion.wires.length > 1) {
            wireAmount =
              i === 0
                ? data.wireSelection.suggestion.rating.mainWireAmount
                : data.wireSelection.suggestion.rating.secondaryWireAmount;
          }
          if (!wireAmount) {
            wireAmount = 1;
          }

          let stringParts = [];
          if (wire["Aderzahl-1,4"] > 0)
            stringParts.push(`${wire["Aderzahl-1,4"] / 4}x4x1,4`);
          if (wire["Aderzahl-1,2"] > 0)
            stringParts.push(`${wire["Aderzahl-1,2"] / 4}x4x1,2`);
          if (wire["Aderzahl-0,9"] > 0)
            stringParts.push(`${wire["Aderzahl-0,9"] / 4}x4x0,9`);

          let wireString = `(${stringParts.join(" + ")})`;

          //   if (wire["Aderzahl-1,4"] > 0) {
          //     if (wire["Aderzahl-0,9"] > 0) {
          //       wireString = `(${wire["Aderzahl-1,4"] / 4}x4x1,4 + ${
          //         wire["Aderzahl-0,9"] / 4
          //       }x4x0,9)`;
          //     } else {
          //       wireString = `(${wire["Aderzahl-1,4"] / 4}x4x1,4)`;
          //     }
          //   } else {
          //     wireString = `(${wire["Aderzahl-0,9"] / 4}x4x0,9)`;
          //   }
          if (wireAmount > 1) {
            wireString = `${wireAmount} x ${wireString}`;
          }
          return i === 0 ? wireString : acc + ` & ${wireString}`;
        },
        null
      );
    }
    const wireSelection = `${data.wireSelection.model.main}${
      data.wireSelection.model.sub ? ` - ${data.wireSelection.model.sub}` : ""
    }`;
    const sleeveCondition =
      data.sleeve &&
      ((data.sleeve.inline &&
        !isNaN(data.sleeve.inline) &&
        data.sleeve.inline > 0) ||
        (data.sleeve.aufteil &&
          !isNaN(data.sleeve.aufteil) &&
          data.sleeve.aufteil > 0));
    const sleeveInline =
      sleeveCondition &&
      data.sleeve.inline &&
      !isNaN(data.sleeve.inline) &&
      data.sleeve.inline > 0
        ? `${data.sleeve.inline} x Verbindungsmuffen`
        : null;
    const sleeveAufteil =
      sleeveCondition &&
      data.sleeve.aufteil &&
      !isNaN(data.sleeve.aufteil) &&
      data.sleeve.aufteil > 0
        ? `${data.sleeve.aufteil} x Abzweigmuffen`
        : null;
    const usageTitle =
      data.combinationType === "new" ? "Neue Anlage" : "Ersatz";

    return (
      <div className="request-item-wrapper">
        <div className="indexLabel">{this.props.index + 1}.</div>
        <div className="property-list">
          <div className="item">
            <div className="key">Bauart</div>
            <div className="value">{usageTitle}</div>
          </div>
          <div className="item">
            <div className="key">Einsatzzweck</div>
            <div className="value">{data.wireSelection.type}</div>
          </div>
          <div className="item">
            <div className="key">Ausführung</div>
            <div className="value">{wireSelection}</div>
          </div>
          <div className="item">
            <div className="key">Aderkombination</div>
            <div className="value">{suggestion}</div>
          </div>
          <div className="item">
            <div className="key">Kabellänge</div>
            <div className="value">{`${data.wireLength} m`}</div>
          </div>
          {sleeveCondition ? (
            <div className="item">
              <div className="key">Muffen</div>
              <div className="value">
                {sleeveInline ? sleeveInline : ""}
                {sleeveAufteil ? (
                  sleeveInline ? (
                    <>, {sleeveAufteil}</>
                  ) : (
                    sleeveAufteil
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="controls">
          {this.props.cartWorkingItem.inEditIndex === this.props.index ? (
            <div className="control inEdit">in Bearbeitung</div>
          ) : (
            <div className="control edit" onClick={this.editItem}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </div>
          )}
          <div className="control" onClick={this.removeItem}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cartWorkingItem: state.cartWorkingItem,
});

export default connect(mapStateToProps, { removeFromCart, editCartItem })(Item);
