import React, {Component} from 'react'
import './InfoBlock.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


class InfoBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <div id="infoblock">
            <div className="icon-wrapper">
                {this.props.type === 'attention' ?
                <FontAwesomeIcon icon={faExclamationTriangle} /> :
                <FontAwesomeIcon icon={faInfoCircle} />}
            </div>
            <div className="content">{this.props.children}</div>
        </div>
    }
}

export default InfoBlock