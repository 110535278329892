import types from "../type/types";
import cartReducers from './cart'
import sleeveReducers from './sleeve'
import wireLengthReducers from './wireLength'
import combinationReducers from './combination'
import wireSelectionReducers from './wireSelection'

const defaultState = {
    cartItems: [],
    cartWorkingItem: {}
}

function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.PERSIST_TO_CART:
            return cartReducers.persist(state, action)
        case types.REMOVE_FROM_CART:
            return cartReducers.remove(state, action)
        case types.EDIT_CART_ITEM:
            return cartReducers.edit(state, action)
        case types.RESET_EVERYTHING:
            return defaultState
        case types.SET_SLEEVE:
            return sleeveReducers.set(state, action)
        case types.SET_WIRE_LENGTH:
            return wireLengthReducers.set(state, action)
        case types.SET_COMBINATION:
            return combinationReducers.set(state, action)
        case types.SET_COMBINATION_TYPE:
            return combinationReducers.setType(state, action)
        case types.SET_WIRE_SELECTION_TYPE:
            return wireSelectionReducers.setType(state,action)
        case types.SET_WIRE_SELECTION_MODEL:
            return wireSelectionReducers.setModel(state,action)
        case types.SET_WIRE_SELECTION_SUGGESTION:
            return wireSelectionReducers.setSuggestion(state,action)
        case types.SET_STATE:
            return action.payload
        default: return state
    }
}

export default reducer