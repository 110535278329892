import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import utility from '../../utility'
import axios from 'axios'
import './Importer.scss'

class Importer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            wireFileInputTitle: "CSV-Datei auswählen",
            sleeveFileInputTitle: "CSV-Datei auswählen",
            oldCombinationFileInputTitle: "CSV-Datei auswählen",
            selectedWireFile: null,
            selectedSleeveFile: null,
            selectedoldCombinationFile: null,
            currentWireProgress: null,
            currentSleeveProgress: null,
            currentOldCombinationProgress: null
        }
        this.updateSelectedFile = this.updateSelectedFile.bind(this)
        this.wireFileSelected = this.wireFileSelected.bind(this)
        this.sleeveFileSelected = this.sleeveFileSelected.bind(this)
        this.oldCombinationFileSelected = this.oldCombinationFileSelected.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.uploadWireFile = this.uploadWireFile.bind(this)
        this.uploadSleeveFile = this.uploadSleeveFile.bind(this)
        this.uploadOldCombinationFile = this.uploadOldCombinationFile.bind(this)
    }

    updateSelectedFile(files, type) {
        if (files.length > 0) {
            const file = files[0]
            if (type === 'wire') {
                this.setState({ ...this.state, selectedWireFile: file, wireFileInputTitle: file.name })
            } else if (type === 'sleeve') {
                this.setState({ ...this.state, selectedSleeveFile: file, sleeveFileInputTitle: file.name })
            } else if (type === 'old-combination') {
                this.setState({ ...this.state, selectedOldCombinationFile: file, oldCombinationFileInputTitle: file.name })
            }
        } else {
            if (type === 'wire') {
                this.setState({ ...this.state, selectedWireFile: null, wireFileInputTitle: "CSV-Datei auswählen" })
            } else if (type === 'sleeve') {
                this.setState({ ...this.state, selectedSleeveFile: null, sleeveFileInputTitle: "CSV-Datei auswählen" })
            } else if (type === 'old-combination') {
                this.setState({ ...this.state, selectedOldCombinationFile: null, oldCombinationFileInputTitle: "CSV-Datei auswählen" })

            }
        }
    }

    wireFileSelected(e) {
        this.updateSelectedFile(e.target.files, 'wire')
    }

    sleeveFileSelected(e) {
        this.updateSelectedFile(e.target.files, 'sleeve')
    }

    oldCombinationFileSelected(e) {
        this.updateSelectedFile(e.target.files, 'old-combination')
    }

    async uploadFile(formData, url, type) {
        try {
            const data = await axios.request({
                method: "post",
                url,
                data: formData,
                onUploadProgress: (p) => {
                    if (100 / p.total * p.loaded === 100) {
                        if (type === 'wire') {
                            this.setState({ ...this.state, currentWireProgress: 'Verarbeitung läuft...' })
                        } else if (type === 'sleeve') {
                            this.setState({ ...this.state, currentSleeveProgress: 'Verarbeitung läuft...' })
                        } else if (type === 'old-combination') {
                            this.setState({ ...this.state, currentOldCombinationProgress: 'Verarbeitung läuft...' })
                        }
                    } else {
                        if (type === 'wire') {
                            this.setState({ ...this.state, currentWireProgress: `${100 / p.total * p.loaded} %` })
                        } else if (type === 'sleeve') {
                            this.setState({ ...this.state, currentSleeveProgress: `${100 / p.total * p.loaded} %` })
                        } else if (type === 'old-combination') {
                            this.setState({ ...this.state, currentOldCombinationProgress: `${100 / p.total * p.loaded} %` })
                        }
                    }
                }
            })
            if (data.data.status) {
                if (type === 'wire') {
                    this.setState({ ...this.state, currentWireProgress: "Liste erfolgreich verarbeitet. Eine Liste von möglichen Aderkombinationen wird nun erstellt, dieser Prozess kann bis zu 30 Minuten dauern. In dieser Zeit können die Vorschläge zu gewählten Aderkombinationen im Konfigurator abweichen. Sie müssen diese Seite nicht wärend diese Prozesses geöffnet haben.", selectedWireFile: null, wireFileInputTitle: "CSV-Datei auswählen" })
                } else if (type === 'sleeve') {
                    this.setState({ ...this.state, currentSleeveProgress: "Liste erfolgreich verarbeitet", selectedSleeveFile: null, sleeveFileInputTitle: "CSV-Datei auswählen" })
                } else if (type === 'old-combination') {
                    this.setState({ ...this.state, currentOldCombinationProgress: "Liste erfolgreich verarbeitet", selectedOldCombinationFile: null, oldCombinationFileInputTitle: "CSV-Datei auswählen" })
                }
            } else {
                if (type === 'wire') {
                    this.setState({ ...this.state, currentWireProgress: "Fehler beim Verarbeiten der Liste, bitte überprüfen Sie die Attribute der CSV Liste", selectedWireFile: null, wireFileInputTitle: "CSV-Datei auswählen" })
                } else if (type === 'sleeve') {
                    this.setState({ ...this.state, currentSleeveProgress: "Fehler beim Verarbeiten der Liste, bitte überprüfen Sie die Attribute der CSV Liste", selectedSleeveFile: null, sleeveFileInputTitle: "CSV-Datei auswählen" })
                } else if (type === 'old-combination') {
                    this.setState({ ...this.state, currentOldCombinationProgress: "Fehler beim Verarbeiten der Liste, bitte überprüfen Sie die Attribute der CSV Liste", selectedOldCombinationFile: null, oldCombinationFileInputTitle: "CSV-Datei auswählen" })
                }
            }
        } catch (err) {
            console.log(err);
            if (type === 'wire') {
                this.setState({ ...this.state, currentWireProgress: "Fehler beim Verarbeiten der Liste, bitte überprüfen Sie die Attribute der CSV Liste", selectedWireFile: null, wireFileInputTitle: "CSV-Datei auswählen" })
            } else if (type === 'sleeve') {
                this.setState({ ...this.state, currentSleeveProgress: "Fehler beim Verarbeiten der Liste, bitte überprüfen Sie die Attribute der CSV Liste", selectedSleeveFile: null, sleeveFileInputTitle: "CSV-Datei auswählen" })
            } else if (type === 'old-combination') {
                this.setState({ ...this.state, currentOldCombinationProgress: "Fehler beim Verarbeiten der Liste, bitte überprüfen Sie die Attribute der CSV Liste", selectedOldCombinationFile: null, oldCombinationFileInputTitle: "CSV-Datei auswählen" })
            }
        }
    }

    async uploadWireFile() {
        var formData = new FormData();
        formData.append("file", this.state.selectedWireFile);
        await this.uploadFile(formData, `${utility.backendDomain}/import/wire`, 'wire')
    }

    async uploadSleeveFile() {
        const formData = new FormData();
        formData.append("file", this.state.selectedSleeveFile)
        await this.uploadFile(formData, `${utility.backendDomain}/import/sleeve`, 'sleeve')
    }

    async uploadOldCombinationFile() {
        const formData = new FormData();
        formData.append("file", this.state.selectedOldCombinationFile)
        await this.uploadFile(formData, `${utility.backendDomain}/import/old-combination`, 'old-combination')
    }
    
    render() {
        return <div id="importer">
            <Container>
                <div className="headline">Liste der verfügbaren Adern aktualisieren</div>
                <div className="description">Alle bisherigen Adern werden aus der Datenbank gelöscht und mit der neuen Liste ersetzt.</div>
        <div className="attribute-list">Folgende Attribute muss die CSV auf Zeile 1 beinhalten: TypNr;Ausführung;Bezeichnung-1-;Bezeichnung-2;Stammkabel;Stichkabel;Aufteilkabel;Aderzahl 1,4;Aderzahl 0,9;DM über Mantel [mm];DM Induktion [mm];DM Armieren [mm];Außen-DM [mm];Innenmuffe;Außenmuffe;Bausatz LK (2x je Muffe);Erdseil;Schrumpfschlauch;Thermoplastisches Abdichtband</div>
                <Form>
                    <Form.File
                        id="custom-file"
                        label={this.state.wireFileInputTitle}
                        custom
                        multi="false"
                        accept="text/csv"
                        onChange={this.wireFileSelected}
                    />
                </Form>
                {this.state.currentWireProgress ?
                    <div className="progress-label">{this.state.currentWireProgress}</div> :
                    ''}
                {this.state.selectedWireFile ? <div className="upload-trigger" onClick={this.uploadWireFile}>Hochladen & Verarbeiten</div> : ''}
            </Container>
            <Container>
                <div className="headline">Liste der Muffen-Daten aktualisieren</div>
                <div className="description">Alle bisherigen Daten werden aus der Datenbank gelöscht und mit der neuen Liste ersetzt.</div>
                <div className="attribute-list">Folgende Attribute muss die CSV auf Zeile 1 beinhalten: Teil;Typnummer</div>
                <Form>
                    <Form.File
                        id="custom-file"
                        label={this.state.sleeveFileInputTitle}
                        custom
                        multi="false"
                        accept="text/csv"
                        onChange={this.sleeveFileSelected}
                    />
                </Form>
                {this.state.currentSleeveProgress ?
                    <div className="progress-label">{this.state.currentSleeveProgress}</div> :
                    ''}
                {this.state.selectedSleeveFile ? <div className="upload-trigger" onClick={this.uploadSleeveFile}>Hochladen & Verarbeiten</div> : ''}
            </Container>
            <Container>
                <div className="headline">Liste der alten Kabel aktualisieren</div>
                <div className="description">Alle bisherigen alten Kabel werden aus der Datenbank gelöscht und mit der neuen Liste ersetzt.</div>
                <div className="attribute-list">Folgende Attribute muss die CSV auf Zeile 1 beinhalten: Ausführung;Gesamt;Anmerkung;Stammkabel;Stichkabel;Aufteilkabel;Aderzahl 1,4;Anzahl Koax-Elemente 1,2/4,4Kx;Aderzahl 1,2;Aderzahl 0,9;DM unter Mantel [mm];DM über Mantel [mm];Außen-DM [mm]</div>
                <Form>
                    <Form.File
                        id="custom-file"
                        label={this.state.oldCombinationFileInputTitle}
                        custom
                        multi="false"
                        accept="text/csv"
                        onChange={this.oldCombinationFileSelected}
                    />
                </Form>
                {this.state.currentOldCombinationProgress ?
                    <div className="progress-label">{this.state.currentOldCombinationProgress}</div> :
                    ''}
                {this.state.selectedOldCombinationFile ? <div className="upload-trigger" onClick={this.uploadOldCombinationFile}>Hochladen & Verarbeiten</div> : ''}
            </Container>
        </div>
    }
}

export default Importer