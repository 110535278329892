import {Component} from 'react'
import {connect} from 'react-redux'
import { setState } from '../../action/state'
import Cookies from 'universal-cookie'
import utility from '../../utility'

class StatePersist extends Component {

    async componentDidMount() {
        const cookies = new Cookies()
        const stateUUID = cookies.get('stateUUID')
        if(stateUUID) {
            const result = await fetch(`${utility.backendDomain}/state/get`, {
                method: 'post',
                body: JSON.stringify({stateUUID}),
                headers: { 'Content-Type': 'application/json' },
              })
              const body = await result.json()
              if(body.status && body.state) {
                this.props.setState(body.state)
              }
        } else {
            const result = await fetch(`${utility.backendDomain}/state/getUUID`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
              })
              const body = await result.json()
              if(body.status && body.uuid) {
                cookies.set('stateUUID', body.uuid)
              }
        }
    }

    async componentDidUpdate() {
        const result = await fetch(`${utility.backendDomain}/state/set`, {
            method: 'post',
            body: JSON.stringify({state: {cartWorkingItem: this.props.cartWorkingItem, inEditIndex: this.props.inEditIndex, cartItems: this.props.cartItems}}),
            headers: { 'Content-Type': 'application/json' },
          })
          const body = await result.json()
          if(!body.status) {
            console.log('Updating state in backend failed!')
          }
    }
    render() {
        return ''
    }
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    cartWorkingItem: state.cartWorkingItem,
    inEditIndex: state.inEditIndex,
    cartItems: state.cartItems
})

export default connect(mapStateToProps, {setState})(StatePersist)