import React, { Component } from "react";
import "./Model.scss";
import Col from "react-bootstrap/Col";
import SubModel from "./SubModel/SubModel";
import utility from "../../../../../utility";
import { connect } from "react-redux";
import { setModel } from "../../../../../action/wireSelection";

class Model extends Component {
  constructor(props) {
    super(props);
    this.selectMain = this.selectMain.bind(this);
    this.selectSub = this.selectSub.bind(this);
    this.hasSubItems = this.hasSubItems.bind(this);
    this.isDisabledSubModel = this.isDisabledSubModel.bind(this);
  }

  hasSubItems() {
    return (
      Array.isArray(this.props.model.sub) && this.props.model.sub.length > 0
    );
  }

  selectMain() {
    if (this.props.model.disabled === false) {
      this.props.setModel({ main: this.props.model.main });
    }
  }

  selectSub(sub) {
    if (this.props.model.disabled === false) {
      this.props.setModel({ main: this.props.model.main, sub });
    }
  }

  isDisabledSubModel(sub) {
    const subModelBlacklist = ["A", "B"];
    return (
      parseInt(this.props.cartWorkingItem.combination["Aderzahl-1,2"]) > 0 &&
      this.props.cartWorkingItem.combinationType === "known" &&
      subModelBlacklist.includes(sub)
    );
  }

  render() {
    if (this.hasSubItems()) {
      return (
        <Col xs={12} md={4}>
          <div className={"wireselection-model"}>
            <div className="main hasSubModels">{this.props.model.main}</div>
            <div className="sub-items">
              {this.props.model.sub.map((sub) => (
                <SubModel
                  disabled={this.isDisabledSubModel(sub)}
                  key={utility.getUUID()}
                  sub={sub}
                  select={this.selectSub}
                />
              ))}
            </div>
            {this.props.model.disabled ? (
              <div className="not-available">nur 0,9er Adern</div>
            ) : (
              ""
            )}
          </div>
        </Col>
      );
    } else {
      return (
        <Col xs={12} md={4}>
          <div className={"wireselection-model"}>
            <div
              className={
                "main" + (this.props.model.disabled ? " disabled" : "")
              }
              onClick={this.selectMain}
            >
              {this.props.model.main}
            </div>
            {this.props.model.disabled &&
            parseInt(this.props.cartWorkingItem.combination["Aderzahl-1,2"]) >
              0 &&
            !this.props.model.main.includes("A-02YSTF(L)2YV") ? (
              <div className="not-available">nur 0,9er Adern</div>
            ) : (
              ""
            )}
          </div>
        </Col>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cartWorkingItem: state.cartWorkingItem,
});

export default connect(mapStateToProps, { setModel })(Model);
