import React, {Component} from 'react'
import './WireLength.scss'
import ConfirmationButton from '../ConfirmationButton/ConfirmationButton'
import {connect} from 'react-redux'
import {setWireLength} from '../../../action/wireLength'

class WireLength extends Component {

    constructor(props) {
        super(props)
        this.state = {length: props.wireLength || ''}
        this.setWireLength = this.setWireLength.bind(this)
        this.submit = this.submit.bind(this)
    }

    submit() {
        this.props.setWireLength(this.state.length > 0 ? this.state.length : null)
        this.props.done()
    }

    setWireLength(e) {
        this.setState({length: e.target.value})
    }

    render() {
        return <div id="wirelength">
                <div className="input-wrapper">
                    <div className="headline">Kabellänge:</div>
                        <div className="input-element-group-wrapper">
                        <div className="input-element-group">
                            <div className="input-element"><input min="1" type="number" value={this.state.length} placeholder="z. B. 260" onChange={this.setWireLength} />&nbsp;m</div>
                        </div>
                    </div>
                </div>
                <ConfirmationButton submit={this.submit} />
            </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    wireLength: state.cartWorkingItem.wireLength
})

export default connect(mapStateToProps, {setWireLength})(WireLength)