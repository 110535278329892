import React, {Component} from 'react'
import './ConfirmationButton.scss'

class ConfirmationButton extends Component {
    constructor(props) {
        super(props)
        this.state = {submit: props.submit}
    }

    render() {
        return <div className="confirmationButton-wrapper">
            <div className="confirmationButton" onClick={this.state.submit}>Übernehmen</div>
        </div>
    }
}

export default ConfirmationButton