import React, { Component } from "react";
import "./SubModel.scss";

class SubModel extends Component {
  constructor(props) {
    super(props);
    this.state = { sub: props.sub };
    this.select = this.select.bind(this);
  }

  select() {
    this.props.select(this.state.sub);
  }

  render() {
    return (
      <div
        className={`submodel-item ${this.props.disabled && "disabled"}`}
        onClick={this.props.disabled ? null : this.select}
      >
        {this.state.sub}
      </div>
    );
  }
}

export default SubModel;
