import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./CheckoutForm.scss";
import utility from "../../../../utility";
import Overlay from "./Overlay/Overlay";
import { connect } from "react-redux";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      company: "",
      mail: "",
      postcode: "",
      phone: "",
      message: "",
      showCheckoutSuccessOverlay: false,
      showCheckoutErrorOverlay: false,
      showCheckoutLoadingOverlay: false,
    };
    this.updateInput = this.updateInput.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
  }

  close() {
    this.props.close();
  }

  updateInput(inputName) {
    return (e) => {
      this.setState({ ...this.state, [inputName]: e.target.value });
    };
  }

  async submit(e) {
    e.preventDefault();
    try {
      this.setState({
        ...this.state,
        showCheckoutErrorOverlay: false,
        showCheckoutSuccessOverlay: false,
        showCheckoutLoadingOverlay: true,
      });
      const customerInformation = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        company: this.state.company,
        mail: this.state.mail,
        postcode: this.state.postcode,
        phone: this.state.phone,
        message: this.state.message,
      };
      const newCartItems = this.props.cartItems;
      // const newCartItems = this.props.cartItems.map((item) => {
      //   return {
      //     ...item,
      //     wireSelection: {
      //       ...item.wireSelection,
      //       suggestion: {
      //         ...item.wireSelection.suggestion,
      //         wires: item.wireSelection.suggestion.wires.map((wire) => {
      //           const newObject = {};
      //           Object.keys(wire).forEach((key) => {
      //             if (wire[key] === -1 || wire[key] === "-1") {
      //               newObject[key] = "?";
      //             } else newObject[key] = wire[key];
      //           });
      //           return newObject;
      //         }),
      //       },
      //     },
      //   };
      // });

      const result = await fetch(`${utility.backendDomain}/request`, {
        method: "post",
        body: JSON.stringify({
          customerInformation,
          cartItems: newCartItems,
        }),
        headers: { "Content-Type": "application/json" },
      });
      const body = await result.json();
      if (body.status) {
        this.setState({
          firstname: "",
          lastname: "",
          company: "",
          mail: "",
          postcode: "",
          phone: "",
          message: "",
          showCheckoutSuccessOverlay: true,
          showCheckoutErrorOverlay: false,
          showCheckoutLoadingOverlay: false,
        });
      } else {
        this.setState({
          ...this.state,
          showCheckoutErrorOverlay: true,
          showCheckoutSuccessOverlay: false,
          showCheckoutLoadingOverlay: false,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        ...this.state,
        showCheckoutErrorOverlay: true,
        showCheckoutSuccessOverlay: false,
        showCheckoutLoadingOverlay: false,
      });
    }
  }

  closeOverlay() {
    if (this.state.showCheckoutSuccessOverlay) {
      this.props.done();
    }
    this.setState({
      ...this.state,
      showCheckoutErrorOverlay: false,
      showCheckoutSuccessOverlay: false,
      showCheckoutLoadingOverlay: false,
    });
  }

  render() {
    return (
      <>
        <Modal id="CheckoutForm" show={true} onHide={this.close} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Anfrage Informationen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.submit}>
              <Form.Group controlId="firstname">
                <Form.Label>Vorname*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vorname"
                  name="firstname"
                  required
                  value={this.state.firstname}
                  onChange={this.updateInput("firstname")}
                />
              </Form.Group>
              <Form.Group controlId="lastname">
                <Form.Label>Nachname*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nachname"
                  name="lastname"
                  required
                  value={this.state.lastname}
                  onChange={this.updateInput("lastname")}
                />
              </Form.Group>
              <Form.Group controlId="company">
                <Form.Label>Firma</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Firma"
                  name="company"
                  value={this.state.company}
                  onChange={this.updateInput("company")}
                />
              </Form.Group>
              <Form.Group controlId="mail">
                <Form.Label>E-Mail*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="E-Mail"
                  name="mail"
                  required
                  value={this.state.mail}
                  onChange={this.updateInput("mail")}
                />
              </Form.Group>
              <Form.Group controlId="postcode">
                <Form.Label>Postleitzahl*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Postleitzahl"
                  name="postcode"
                  value={this.state.postcode}
                  required
                  onChange={this.updateInput("postcode")}
                />
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Telefon"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.updateInput("phone")}
                />
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label>Nachricht</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={this.state.message}
                  onChange={this.updateInput("message")}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="submit-button">
                Anfrage senden
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        {this.state.showCheckoutLoadingOverlay ? (
          <Overlay
            message={"Anfrage wird verarbeitet..."}
            showClose={false}
            close={this.closeOverlay}
          />
        ) : (
          ""
        )}
        {this.state.showCheckoutSuccessOverlay ? (
          <Overlay
            message={
              "Anfrage verarbeitet. Wir treten schnellstmöglich mit Ihnen in Kontakt."
            }
            showClose={true}
            close={this.closeOverlay}
          />
        ) : (
          ""
        )}
        {this.state.showCheckoutErrorOverlay ? (
          <Overlay
            message={
              "Anfrage fehlgeschlagen, bitte versuchen Sie es später nochmals."
            }
            showClose={true}
            close={this.closeOverlay}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cartItems: state.cartItems,
});

export default connect(mapStateToProps)(CheckoutForm);
