import React, {Component} from 'react'
import './WireSelection.scss'
import Container from 'react-bootstrap/Container'
import Type from './Type/Type'
import ModelManager from './ModelManager/ModelManager'
import Redfaktor16 from '../../../assets/image/Redfaktor-16,66-Hz.png'
import Redfaktor50 from '../../../assets/image/Redfaktor-50-Hz.png'
import Kalbelauswahl1 from '../../../assets/image/Kalbelauswahl1.png'
import Kalbelauswahl2 from '../../../assets/image/Kalbelauswahl2.png'
import AttachedImages from './AttachedImages/AttachedImages'
import Suggestion from './Suggestion/Suggestion'
import utility from '../../../utility'
import {connect} from 'react-redux'

class WireSelection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            wireModels:{
                Stammkabel: [{main: 'A-02YSTF(L)2YV'}, {main: 'AJ-02YSTF(L)2YDB2Y', sub: ['A', 'B', 'C']}, {main: 'A-02YSTF(L)2YB2Y'}, {main: 'A-02YSTF(L)2Y(SR)2Y'}],
                Aufteilkabel: [{main: 'A-02YSTF(L)2YV'}],
                Stichkabel: [{main: 'A-02YSTF(L)2YV'}, {main: 'A-02YSTF(L)2YB2Y'}, {main: 'A-02YSTF(L)2Y(SR)2Y'}, {main: 'A-02YSF(L)2YV'}, {main: 'A-02YSF(L)2YB2Y'}, {main: 'A-02YSF(L)2Y(SR)2Y'}],
            }
        }
    }

    render() {
        return <div id="wireselection">
                <Container className="wireselection-type-wrapper">
                    {Object.keys(this.state.wireModels).map((type, index) => <Type key={'wireselection-type-' + index} type={type} select={this.setWireType}/>)}
                </Container>
                {this.props.wireSelection.type === 'Stammkabel' ? <ModelManager models={this.state.wireModels['Stammkabel']} wireType='Stammkabel' /> :''}
                {this.props.wireSelection.type === 'Stammkabel' ? <AttachedImages images={[Redfaktor16, Redfaktor50]} triggerText={'Reduktionsfaktor-Kurven anzeigen'}/> :''}
                {this.props.wireSelection.type === 'Stammkabel' ? <AttachedImages images={[Kalbelauswahl1, Kalbelauswahl2]} triggerText={'Auswahlhilfe anzeigen'}/> :''}
                {this.props.wireSelection.type === 'Aufteilkabel' ? <ModelManager models={this.state.wireModels['Aufteilkabel']} wireType='Aufteilkabel' /> :''}
                {this.props.wireSelection.type === 'Stichkabel' ? <ModelManager models={this.state.wireModels['Stichkabel']} wireType='Stichkabel'/> :''}
                {this.props.wireSelection.type && this.props.wireSelection.model && !this.props.wireSelection.suggestion ? <Suggestion key={utility.getUUID()} done={this.props.done} /> : ''}
            </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    wireSelection: state.cartWorkingItem.wireSelection || {}
})

export default connect(mapStateToProps)(WireSelection)