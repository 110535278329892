import React, {Component} from 'react'
import './Overlay.scss'

class Overlay extends Component {
    constructor(props) {
        super(props)
        this.state = {message: props.message, showClose: props.showClose}
        this.close = this.close.bind(this)
    }

    close() {
        this.props.close()
    }

    render() {
        return <div id="checkoutform-overlay" onClick={this.close}>
            {this.state.showClose ? <div className="close" onClick={this.close}>X</div> : ''}
            <div className="message">{this.state.message}</div>
        </div>
    }
}

export default Overlay