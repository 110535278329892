import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Footer.scss";

class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <Container fluid>
          <Row>
            <Col xs={12} md={6}>
              <div>Otto-Schrimpff-Straße 2</div>
              <div>Germany – 91154 Roth</div>
              <div>Tel.: +49 (0) 09171/806-111</div>
              <div>Tel: +49 (0) 09171/806-222</div>
              <div>E-Mail: kabel@bayka.de</div>
            </Col>
            <Col xs={12} md={6} className="text-right copyright">
              <span>BAYERISCHE KABELWERKE AG &copy; 2020</span>
              <div>
                <a href="/impressum">Impressum</a>
              </div>
              <div>
                <a href="/datenschutz">Datenschutz</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
