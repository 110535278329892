import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import KnowCombination from "./KnownCombination/KnownCombination";
import NewCombination from "./NewCombination/NewCombination";
import WireSelection from "./WireSelection/WireSelection";
import WireLength from "./WireLength/WireLength";
import WireUsage from "./WireUsage/WireUsage";
import Sleeve from "./Sleeve/Sleeve";
import Cart from "./Cart/Cart";
import "./Home.scss";
import utility from "../../utility";
import { connect } from "react-redux";
import { persistToCart } from "../../action/cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wireSelectionOpen: false,
      wireLengthOpen: false,
      sleeveOpen: false,
      wireCombinationOpen: false,
      wireUsageOpen: true,
      knownSelectorVisible: false,
      newSelectorVisible: false,
    };
    this.toggleWireSelectionSection =
      this.toggleWireSelectionSection.bind(this);
    this.toggleWireLengthSection = this.toggleWireLengthSection.bind(this);
    this.toggleSleeveSection = this.toggleSleeveSection.bind(this);
    this.toggleWireCombinationSection =
      this.toggleWireCombinationSection.bind(this);
    this.toggleWireUsageSection = this.toggleWireUsageSection.bind(this);
    this.hideAllSection = this.hideAllSection.bind(this);
    this.getAllToggesDisabledState = this.getAllToggesDisabledState.bind(this);
    this.getWireLengthTitle = this.getWireLengthTitle.bind(this);
    this.getSleeveTitle = this.getSleeveTitle.bind(this);
    this.isCombinationActive = this.isCombinationActive.bind(this);
    this.getWireCombinationTitle = this.getWireCombinationTitle.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      Object.keys(this.props.cartWorkingItem).length === 0 &&
      Object.keys(prevProps.cartWorkingItem).length > 0
    ) {
      this.toggleWireUsageSection();
    }
  }

  getAllToggesDisabledState() {
    return {
      ...this.state,
      wireSelectionOpen: false,
      wireLengthOpen: false,
      sleeveOpen: false,
      wireCombinationOpen: false,
      wireUsageOpen: false,
    };
  }

  toggleWireSelectionSection() {
    this.setState({
      ...this.getAllToggesDisabledState(),
      wireSelectionOpen: true,
    });
  }

  toggleWireLengthSection() {
    this.setState({
      ...this.getAllToggesDisabledState(),
      wireLengthOpen: true,
    });
  }

  toggleSleeveSection() {
    this.setState({ ...this.getAllToggesDisabledState(), sleeveOpen: true });
  }

  toggleWireCombinationSection() {
    this.setState({
      ...this.getAllToggesDisabledState(),
      wireCombinationOpen: true,
    });
  }

  toggleWireUsageSection() {
    this.setState({ ...this.getAllToggesDisabledState(), wireUsageOpen: true });
  }

  hideAllSection() {
    this.setState({ ...this.getAllToggesDisabledState() });
  }

  getWireLengthTitle() {
    let wireLengthTitle = `4. Kabellänge`;
    if (this.props.cartWorkingItem.wireLength != null) {
      return (
        <>
          <span className="section-headline-main-part">
            {wireLengthTitle}:{" "}
          </span>
          {`${this.props.cartWorkingItem.wireLength} m`}
        </>
      );
    }
    return (
      <span className="section-headline-main-part">{wireLengthTitle}</span>
    );
  }

  getSleeveTitle() {
    let sleeveTitle = "5. Anzahl Muffen-Sets";
    if (this.props.cartWorkingItem.sleeve) {
      if (this.props.cartWorkingItem.sleeve.inline > 0) {
        if (this.props.cartWorkingItem.sleeve.aufteil > 0) {
          return (
            <>
              <span className="section-headline-main-part">
                {sleeveTitle}:{" "}
              </span>
              {`${this.props.cartWorkingItem.sleeve.inline} x Verbindungsmuffe; ${this.props.cartWorkingItem.sleeve.aufteil} x Abzweigmuffen`}
            </>
          );
        } else {
          return (
            <>
              <span className="section-headline-main-part">
                {sleeveTitle}:{" "}
              </span>
              {`${this.props.cartWorkingItem.sleeve.inline} x Verbindungsmuffe`}
            </>
          );
        }
      } else if (this.props.cartWorkingItem.sleeve.aufteil > 0) {
        return (
          <>
            <span className="section-headline-main-part">{sleeveTitle}: </span>
            {`${this.props.cartWorkingItem.sleeve.aufteil} x Abzweigmuffen`}
          </>
        );
      }
    } else {
      return <span className="section-headline-main-part">{sleeveTitle}</span>;
    }
  }

  getWireCombinationTitle() {
    let wireCombinationTitle = "2. Aderkombination";
    if (
      this.props.cartWorkingItem &&
      this.props.cartWorkingItem.combinationType === "new" &&
      this.props.cartWorkingItem.combination
    ) {
      const data = this.props.cartWorkingItem.combination;
      return (
        <>
          <span className="section-headline-main-part">
            {wireCombinationTitle}:{" "}
          </span>
          {`${data["Aderzahl-1,4"]}x4x1,4 + ${data["Aderzahl-0,9"]}x4x0,9`}
        </>
      );
    } else if (
      this.props.cartWorkingItem &&
      this.props.cartWorkingItem.combinationType === "known" &&
      this.props.cartWorkingItem.combination
    ) {
      const data = this.props.cartWorkingItem.combination;
      // return <><span className="section-headline-main-part">{wireCombinationTitle}: </span>{`${data['Ausfuehrung']} ${(data['Aderzahl-1,4'] + data['Anzahl-Koax-Elemente-1,2/4,4Kx'] + data['Aderzahl-1,2'] + data['Aderzahl-0,9']) / 2}´´ (${data['Aderzahl-1,4']/2}/${ + data['Anzahl-Koax-Elemente-1,2/4,4Kx']/2}/${data['Aderzahl-1,2']/2}/${data['Aderzahl-0,9']/2})`} <FontAwesomeIcon icon={faArrowRight} /> {`${(data['Aderzahl-1,4'] + data['Aderzahl-1,2']) / 4}x4x1,4 + ${data['Aderzahl-0,9']/4}x4x0,9`}</>
      // let newTypeWireCombination = ''
      const items = [];
      if (data["Aderzahl-1,4"] > 0)
        items.push(`${data["Aderzahl-1,4"] / 4}x4x1,4`);
      if (data["Aderzahl-1,2"] > 0)
        items.push(`${data["Aderzahl-1,2"] / 4}x4x1,2`);
      if (data["Aderzahl-0,9"] > 0)
        items.push(`${data["Aderzahl-0,9"] / 4}x4x0,9`);
      const newTypeWireCombination = items.join(" + ");
      // if (data['Aderzahl-1,4'] + data['Aderzahl-1,2'] > 0) {
      //     if (data['Aderzahl-0,9'] > 0) {
      //         newTypeWireCombination = `${(data['Aderzahl-1,4'] + data['Aderzahl-1,2']) / 4}x4x1,4 + ${data['Aderzahl-0,9'] / 4}x4x0,9`
      //     } else {
      //         newTypeWireCombination = `${(data['Aderzahl-1,4'] + data['Aderzahl-1,2']) / 4}x4x1,4`
      //     }
      // } else if (data['Aderzahl-0,9'] > 0) {
      //     newTypeWireCombination = `${data['Aderzahl-0,9'] / 4}x4x0,9`
      // }
      return (
        <>
          <span className="section-headline-main-part">
            {wireCombinationTitle}:{" "}
          </span>
          {`${data["Ausfuehrung"]} ${data["Gesamt"]} `}{" "}
          <FontAwesomeIcon icon={faArrowRight} /> {newTypeWireCombination}
        </>
      );
    } else {
      return (
        <span className="section-headline-main-part">
          {wireCombinationTitle}
        </span>
      );
    }
  }

  getWireUsageTitle() {
    let wireUsageTitle = "1. Kabelanwendung";
    if (
      this.props.cartWorkingItem &&
      this.props.cartWorkingItem.combinationType
    ) {
      return (
        <>
          <span className="section-headline-main-part">{wireUsageTitle}: </span>
          {`${
            this.props.cartWorkingItem.combinationType === "new"
              ? "Neue Anlage"
              : "Ersatz für Kabel nach Dlk 1.01.106"
          }`}
        </>
      );
    } else {
      return (
        <span className="section-headline-main-part">{wireUsageTitle}</span>
      );
    }
  }

  getWireSelectionTitle() {
    let wireSelectionTitle = "3. Kabelauswahl";
    if (
      this.props.cartWorkingItem.wireSelection &&
      this.props.cartWorkingItem.wireSelection.type &&
      this.props.cartWorkingItem.wireSelection.model &&
      this.props.cartWorkingItem.wireSelection.model.main
    ) {
      const wireSelection = this.props.cartWorkingItem.wireSelection;
      if (
        wireSelection?.suggestion &&
        wireSelection?.suggestion?.wires?.length > 0
      ) {
        // the first if-clause section if for second level suggestions
        if (
          wireSelection.suggestion.rating.mainWireAmount &&
          wireSelection.suggestion.rating.secondaryWireAmount
        ) {
          const suggestions = wireSelection.suggestion.wires.reduce(
            (acc, wire, i) => {
              let wireAmount =
                wireSelection.suggestion.rating.mainWireAmount +
                wireSelection.suggestion.rating.secondaryWireAmount;
              if (wireSelection.suggestion.wires.length > 1) {
                wireAmount =
                  i === 0
                    ? wireSelection.suggestion.rating.mainWireAmount
                    : wireSelection.suggestion.rating.secondaryWireAmount;
              }
              const stringItems = [];
              if (wire["Aderzahl-1,4"] > 0)
                stringItems.push(`${wire["Aderzahl-1,4"] / 4}x4x1,4`);
              if (wire["Aderzahl-1,2"] > 0)
                stringItems.push(`${wire["Aderzahl-1,2"] / 4}x4x1,2`);
              if (wire["Aderzahl-0,9"] > 0)
                stringItems.push(`${wire["Aderzahl-0,9"] / 4}x4x0,9`);

              const wireString = `${wireAmount} x (${stringItems.join(" + ")})`;
              // if (wire['Aderzahl-1,4'] > 0) {
              //     if (wire['Aderzahl-0,9'] > 0) {
              //         wireString = `${wireAmount} x (${wire['Aderzahl-1,4'] / 4}x4x1,4 + ${wire['Aderzahl-0,9'] / 4}x4x0,9)`
              //     } else {
              //         wireString = `${wireAmount} x (${wire['Aderzahl-1,4'] / 4}x4x1,4)`
              //     }
              // } else {
              //     wireString = `${wireAmount} x (${wire['Aderzahl-0,9'] / 4}x4x0,9)`
              // }
              return i === 0 ? wireString : acc + ` & ${wireString}`;
            },
            null
          );
          return (
            <>
              <span className="section-headline-main-part">
                {wireSelectionTitle}:{" "}
              </span>
              {`${wireSelection.model.main}${
                wireSelection.model.sub ? ` ${wireSelection.model.sub}` : ""
              }: ${suggestions}`}
            </>
          );
        } else {
          // this section if for pre calculated suggestions
          let wireAmount = 1;
          const suggestions = wireSelection.suggestion.wires.reduce(
            (acc, wire, i) => {
              //   let wireString = "";
              //   if (wire["Aderzahl-1,4"] > 0) {
              //     if (wire["Aderzahl-0,9"] > 0) {
              //       wireString = `${wireAmount} x (${
              //         wire["Aderzahl-1,4"] / 4
              //       }x4x1,4 + ${wire["Aderzahl-0,9"] / 4}x4x0,9)`;
              //     } else {
              //       wireString = `${wireAmount} x (${
              //         wire["Aderzahl-1,4"] / 4
              //       }x4x1,4)`;
              //     }
              //   } else {
              //     wireString = `${wireAmount} x (${
              //       wire["Aderzahl-0,9"] / 4
              //     }x4x0,9)`;
              //   }
              const stringItems = [];
              if (wire["Aderzahl-1,4"] > 0)
                stringItems.push(`${wire["Aderzahl-1,4"] / 4}x4x1,4`);
              if (wire["Aderzahl-1,2"] > 0)
                stringItems.push(`${wire["Aderzahl-1,2"] / 4}x4x1,2`);
              if (wire["Aderzahl-0,9"] > 0)
                stringItems.push(`${wire["Aderzahl-0,9"] / 4}x4x0,9`);

              const wireString = `${wireAmount} x (${stringItems.join(" + ")})`;
              return i === 0 ? wireString : acc + ` & ${wireString}`;
            },
            null
          );
          return (
            <>
              <span className="section-headline-main-part">
                {wireSelectionTitle}:{" "}
              </span>
              {`${wireSelection.model.main}${
                wireSelection.model.sub ? ` ${wireSelection.model.sub}` : ""
              }: ${suggestions}`}
            </>
          );
        }
      } else {
        return (
          <>
            <span className="section-headline-main-part">
              {wireSelectionTitle}:{" "}
            </span>
            {`${wireSelection.model.main}${
              wireSelection.model.sub ? ` ${wireSelection.model.sub}` : ""
            }`}
          </>
        );
      }
    } else {
      return (
        <span className="section-headline-main-part">{wireSelectionTitle}</span>
      );
    }
  }

  isCombinationActive(type) {
    return (
      this.props.cartWorkingItem &&
      this.props.cartWorkingItem.combinationType &&
      this.props.cartWorkingItem.combinationType === type
    );
  }

  render() {
    return (
      <div id="home">
        <Container>
          <div className="h1 text-center">
            Auswahlhilfe für Fernmeldekabel der Deutschen Bahn AG
          </div>
          <div className="h2 text-center">nach TL 416.0530</div>
          <Accordion className="configurator-accordion">
            <Card>
              <Card.Header onClick={this.toggleWireUsageSection}>
                <Accordion.Toggle as="div">
                  {this.getWireUsageTitle()}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse in={this.state.wireUsageOpen}>
                <Card.Body>
                  <WireUsage done={this.toggleWireCombinationSection} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header onClick={this.toggleWireCombinationSection}>
                <Accordion.Toggle as="div">
                  {this.getWireCombinationTitle()}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse in={this.state.wireCombinationOpen}>
                <Card.Body>
                  {this.isCombinationActive("known") ||
                  this.isCombinationActive("new") ? (
                    this.isCombinationActive("known") ? (
                      <KnowCombination
                        key={utility.getUUID()}
                        done={this.toggleWireSelectionSection}
                      />
                    ) : (
                      <NewCombination
                        key={utility.getUUID()}
                        done={this.toggleWireSelectionSection}
                      />
                    )
                  ) : (
                    "Bitte führen Sie die Schritte in der vorgesehenen Reihenfolge durch"
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header onClick={this.toggleWireSelectionSection}>
                <Accordion.Toggle as="div">
                  {this.getWireSelectionTitle()}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse in={this.state.wireSelectionOpen}>
                <Card.Body>
                  {this.props.cartWorkingItem &&
                  this.props.cartWorkingItem.combination ? (
                    <WireSelection
                      key={utility.getUUID()}
                      done={this.toggleWireLengthSection}
                    />
                  ) : (
                    "Bitte führen Sie die Schritte in der vorgesehenen Reihenfolge durch"
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header onClick={this.toggleWireLengthSection}>
                <Accordion.Toggle as="div">
                  {this.getWireLengthTitle()}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse in={this.state.wireLengthOpen}>
                <Card.Body>
                  <WireLength
                    key={utility.getUUID()}
                    done={this.toggleSleeveSection}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header onClick={this.toggleSleeveSection}>
                <Accordion.Toggle as="div">
                  {this.getSleeveTitle()}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse in={this.state.sleeveOpen}>
                <Card.Body>
                  {this.props.cartWorkingItem &&
                  this.props.cartWorkingItem.wireSelection &&
                  this.props.cartWorkingItem.wireSelection.suggestion &&
                  this.props.cartWorkingItem.combination &&
                  (this.isCombinationActive("known") ||
                    this.isCombinationActive("new")) ? (
                    <Sleeve
                      key={utility.getUUID()}
                      done={this.hideAllSection}
                    />
                  ) : (
                    "Bitte führen Sie die Schritte in der vorgesehenen Reihenfolge durch"
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
        <Cart />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cartWorkingItem: state.cartWorkingItem || {},
});

export default connect(mapStateToProps, { persistToCart })(Home);
