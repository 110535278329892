import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Imprint from "./components/Imprint/Imprint";
import DataProtection from "./components/DataProtection/DataProtection";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Importer from "./components/Importer/Importer";
import StatePersist from "./components/StatePersist/StatePersist";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const iFrameViewEnabled = window.location.search.includes("iframe=true");
  return (
    <>
      <Header iframe={iFrameViewEnabled} />
      <StatePersist />
      <div
        id="main-container"
        className={iFrameViewEnabled === true ? "iframe-view" : ""}
      >
        <Router>
          <Switch>
            <Route exact={true} path="/">
              <Home />
            </Route>
            <Route exact={true} path="/impressum">
              <Imprint />
            </Route>
            <Route exact={true} path="/datenschutz">
              <DataProtection />
            </Route>
            <Route exact={true} path="/import">
              <Importer />
            </Route>
          </Switch>
        </Router>
      </div>
      {!iFrameViewEnabled ? <Footer /> : ""}
    </>
  );
}

export default App;
