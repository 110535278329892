import React, { Component } from 'react'
import './Cart.scss'
import Container from 'react-bootstrap/Container'
import Item from './Item/Item'
import CheckoutForm from './CheckoutForm/CheckoutForm'
import utility from '../../../utility'
import { connect } from 'react-redux'
import { resetEverything } from '../../../action/reset'
import { persistToCart } from '../../../action/cart'
import InfoBlock from '../InfoBlock/InfoBlock'

class Cart extends Component {
    constructor(props) {
        super(props)
        this.state = { showCheckoutForm: false, showCurrentConfiguration: false }
        this.completeRequest = this.completeRequest.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.done = this.done.bind(this)
        this.isCartWorkingItemValid = this.isCartWorkingItemValid.bind(this)
        this.persist = this.persist.bind(this)
    }

    completeRequest() {
        this.setState({ ...this.state, showCheckoutForm: true })
    }

    persist() {
        this.props.persistToCart()
    }

    closeModal() {
        this.setState({ ...this.state, showCheckoutForm: false })
    }

    done() {
        this.setState({ ...this.state, showCheckoutForm: false })
        this.props.resetEverything()
    }

    isCartWorkingItemValid() {
        return this.props.cartWorkingItem && this.props.cartWorkingItem.wireLength && this.props.cartWorkingItem.combination && this.props.cartWorkingItem.combinationType && this.props.cartWorkingItem.wireSelection && this.props.cartWorkingItem.wireSelection.type && this.props.cartWorkingItem.wireSelection.model ? true : false
    }

    render() {
        return <div id="cart">
            <Container className="add-to-cart-wrapper">
                {this.isCartWorkingItemValid() ?
                    <div className="add-to-cart" onClick={this.persist}>Bestätigen</div>
                    :
                    <><div className="add-to-cart disabled">Bestätigen</div>
                    <InfoBlock>
                        <div>Konfiguration unvollständig</div>
                    </InfoBlock></>
                    }
            </Container>
            <Container>
                <div className="summary">
                    <div className="headline">Anfrageübersicht</div>
                    {this.props.cartItems.length > 0 ? this.props.cartItems.map((data, i) => <Item key={utility.getUUID()} index={i} data={data} />) : <div className="no-wires-yet">Sie haben noch keine Kombination zu Ihrer Anfrage hinzugefügt.</div>}
                </div>
            </Container>
            <Container className="complete-request-wrapper">
                {(this.props.cartItems.length > 0) ?
                    <div className="complete-request" onClick={this.completeRequest}>Anfrage abschließen</div>
                    : ''}
            </Container>
            {this.state.showCheckoutForm ? <CheckoutForm close={this.closeModal} done={this.done} /> : ''}
        </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    cartWorkingItem: state.cartWorkingItem,
    cartItems: state.cartItems,
})

export default connect(mapStateToProps, { resetEverything, persistToCart })(Cart)