import React, {Component} from 'react'
import Container from 'react-bootstrap/Container'
import './Imprint.scss'

class Imprint extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <div id="imprint">
            <Container>
                <div className="headline">IMPRESSUM</div>
                <div className="text">BAYERISCHE KABELWERKE AG<br/>
                Otto-Schrimpff-Straße 2<br/>
                D – 91154 Roth</div>
                <br/>
                <div className="text">Postfach 1153<br/>
                D – 91152 Roth</div>
                <br/>
                <div className="text">Tel: +49 (0) 09171/806-111<br/>
                Fax: +49 (0) 09171/806-222</div>
                <br/>
                <div className="text">E-Mail: <a href="mailto:kabel@bayka.de">kabel@bayka.de</a></div>
                
                <div className="text"><strong>Vorsitzende des Aufsichtsrates:</strong> Christiane Wilms-Mester<br/>
                <strong>Vorstand:</strong> Johann Erich Wilms</div>
                <div className="text"><strong>Sitz der Gesellschaft:</strong> 91154 Roth<br/>
                <strong>Handelsregister:</strong> HRB 314 beim Amtsgericht Nürnberg<br/>
                <strong>USt-IdNr:</strong> DE133500655<br/>
                <strong>D-U-N-S Nr.:</strong> 31-610-2151</div>
            </Container>
        </div>
    }
}

export default Imprint