import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Model from "./Model/Model";
import utility from "../../../../utility";
import { connect } from "react-redux";

class ModelManager extends Component {
  render() {
    if (this.props.cartWorkingItem.wireSelection.type === "Stichkabel") {
      const excludeModelsIf14er = ["TF(L)2YB2Y", "TF(L)2Y(SR)2Y"];

      const models = this.props.models.map((model) => {
        const valueOf14 = parseInt(
          this.props.cartWorkingItem.combination["Aderzahl-1,4"] ||
            this.props.cartWorkingItem.combination["Aderzahl-1,4"]
        );
        if (!isNaN(valueOf14) && valueOf14 > 0) {
          model.disabled = excludeModelsIf14er.reduce(
            (acc, value) => (acc ? acc : model.main.includes(value)),
            false
          );
        } else {
          model.disabled = false;
        }
        return model;
      });
      const withPetrolat = models.filter((model) => !model.main.includes("TF"));
      const withoutPetrolat = models.filter((model) =>
        model.main.includes("TF")
      );
      return (
        <div id="modelmanager">
          <Container>
            <Row>
              <div className="col-12">Trocken gefüllt:</div>
              {withoutPetrolat.map((model) => (
                <Model key={utility.getUUID()} model={model} />
              ))}
            </Row>
            <Row>
              <div className="col-12">Petrolat gefüllt:</div>
              {withPetrolat.map((model) => (
                <Model key={utility.getUUID()} model={model} />
              ))}
            </Row>
          </Container>
        </div>
      );
    } else if (this.props.cartWorkingItem.wireSelection.type === "Stammkabel") {
      const excludeModelsIf14er = ["2YB2Y", "(SR)2Y"];
      const excludeModelsIf12er = ["A-02YSTF(L)2YV"];

      const models = this.props.models.map((model) => {
        const valueOf14 = parseInt(
          this.props.cartWorkingItem.combination["Aderzahl-1,4"]
        );
        const valueOf12 = parseInt(
          this.props.cartWorkingItem.combination["Aderzahl-1,2"]
        );
        if (!isNaN(valueOf14) && valueOf14 > 0) {
          model.disabled = excludeModelsIf14er.reduce(
            (acc, value) => (acc ? acc : model.main.includes(value)),
            false
          );
        } else {
          model.disabled = false;
        }

        if (!model.disabled) {
          if (
            this.props.cartWorkingItem.combinationType === "known" &&
            valueOf12 > 0
          ) {
            console.log("known");
            model.disabled = excludeModelsIf12er.reduce(
              (acc, value) => (acc ? acc : model.main.includes(value)),
              false
            );
          }
        }

        return model;
      });

      const ajTypes = models.filter((model) => model.main.includes("AJ"));
      const otherTypes = models.filter((model) => !model.main.startsWith("AJ"));
      return (
        <div id="modelmanager">
          <Container>
            <Row>
              {otherTypes.map((model) => (
                <Model key={utility.getUUID()} model={model} />
              ))}
              {ajTypes.map((model) => (
                <Model key={utility.getUUID()} model={model} />
              ))}
            </Row>
          </Container>
        </div>
      );
    } else {
      const models = this.props.models.map((model) => {
        model.disabled = false;
        return model;
      });
      return (
        <div id="modelmanager">
          <Container>
            <Row>
              {models.map((model) => (
                <Model key={utility.getUUID()} model={model} />
              ))}
            </Row>
          </Container>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cartWorkingItem: state.cartWorkingItem,
});

export default connect(mapStateToProps)(ModelManager);
