import React, {Component} from 'react'
import './Result.scss'
import {setCombination} from '../../../../action/combination'
import {connect} from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

class Result extends Component {

    constructor(props) {
        super(props)
        this.state = {data: props.data}
        this.select = this.select.bind(this)
    }

    select() {
        this.props.setCombination(this.state.data)
        this.props.done()
    }

    render() {
        // const content = `Paare je Art: ${(this.state.data['Aderzahl-1,4'] + this.state.data['Anzahl-Koax-Elemente-1,2/4,4Kx'] + this.state.data['Aderzahl-1,2'] + this.state.data['Aderzahl-0,9']) / 2}´´ (${this.state.data['Aderzahl-1,4']/2}/${ + this.state.data['Anzahl-Koax-Elemente-1,2/4,4Kx']/2}/${this.state.data['Aderzahl-1,2']/2}/${this.state.data['Aderzahl-0,9']/2})`
        // return <div className='knowncombination-result' onClick={this.select}>                    
        //         {content} <FontAwesomeIcon icon={faArrowRight} /> {this.state.data['Ausfuehrung']}
        //     </div>

        return <div className='knowncombination-result' onClick={this.select}>                    
                Paare je Art: {this.state.data['Ausfuehrung']} {this.state.data['Gesamt']}
            </div>
    }
}

export default connect(null, {setCombination})(Result)