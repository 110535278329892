const setType = (state, action) => {
    const wireSelection = state.cartWorkingItem.wireSelection || {}
    wireSelection.type = action.payload
    wireSelection.model = null
    wireSelection.suggestion = null
    return {
        ...state, cartWorkingItem: {...state.cartWorkingItem, wireSelection}
    }
}

const setModel = (state, action) => {
    const wireSelection = state.cartWorkingItem.wireSelection || {}
    wireSelection.model = action.payload
    wireSelection.suggestion = null
    return {
        ...state, cartWorkingItem: {...state.cartWorkingItem, wireSelection}
    }
}

const setSuggestion = (state, action) => {
    const wireSelection = state.cartWorkingItem.wireSelection || {}
    wireSelection.suggestion = action.payload
    return {
        ...state, cartWorkingItem: {...state.cartWorkingItem, wireSelection}
    }
}

export default {setType, setModel, setSuggestion}