import types from '../type/types.js'

export const setCombination = combination => ({
    type: types.SET_COMBINATION,
    payload: combination
  })

export const setCombinationType = type => ({
  type: types.SET_COMBINATION_TYPE,
  payload: type
})

  export default {}