const set = (state, action) => {
    return {
        ...state, cartWorkingItem: {...state.cartWorkingItem, combination: action.payload, wireSelection: null }
    }
}

const setType = (state, action) => {
    return {
        ...state, cartWorkingItem: {...state.cartWorkingItem, combination: null, combinationType: action.payload, wireSelection: null}
    }
}

export default {set, setType}