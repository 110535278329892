const persist = (state) => {
    if (state.cartWorkingItem && Object.keys(state.cartWorkingItem).length > 0) {
        const cartItems = [...state.cartItems]
        if (!isNaN(state.cartWorkingItem.inEditIndex)) {
            cartItems[state.cartWorkingItem.inEditIndex] = state.cartWorkingItem
        } else {
            cartItems.push(state.cartWorkingItem)
        }
        return {
            ...state, cartItems, cartWorkingItem: {}
        }
    } else {
        return state
    }
}

const remove = (state, action) => {
    return {
        ...state, cartItems: state.cartItems.filter((item, i) => i !== action.payload)
    }
}

const edit = (state, action) => {
    const item = { ...state.cartItems[action.payload], inEditIndex: action.payload }
    return {
        ...state, cartWorkingItem: item
    }
}

export default { persist, remove, edit }