import React, {Component} from 'react'
import './NewCombination.scss'
import ConfirmationButton from '../ConfirmationButton/ConfirmationButton'
import {connect} from 'react-redux'
import {setCombination} from '../../../action/combination'

class NewCombination extends Component {

    constructor(props) {
        super(props)
        this.state = props.cartWorkingItem && props.cartWorkingItem.combination && props.cartWorkingItem.combinationType === 'new' && props.cartWorkingItem.combination ? props.cartWorkingItem.combination : {'Aderzahl-1,4':'', 'Aderzahl-0,9': ''}
        this.submit = this.submit.bind(this)
    }

    submit() {
        const stateCopy = {...this.state}
        if(!Number.isInteger(stateCopy['Aderzahl-1,4'])) {
            stateCopy['Aderzahl-1,4'] = 0
        }
        if(!Number.isInteger(stateCopy['Aderzahl-0,9'])) {
            stateCopy['Aderzahl-0,9'] = 0
        }
        this.props.setCombination(stateCopy)
        this.props.done()
    }
    
    updateValue(key) {
        return (e) => {
            this.setState({...this.state, [key]: Number.parseInt(e.target.value)})
        }
    }

    render() {
        return <>
            <div id="newcombination">
                <div className="headline">Eingabe (Vierer):</div>
                <div className="input-element-group-wrapper">
                  <div className="input-element-group">
                    <div className="input-element"><input min="1" type="number" value={this.state['Aderzahl-1,4']} placeholder="0" onChange={this.updateValue('Aderzahl-1,4')} />x4x1,4</div>
                  </div>
                  &nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;
                  <div className="input-element-group">
                    <div className="input-element"><input min="1" type="number" value={this.state['Aderzahl-0,9']} placeholder="0" onChange={this.updateValue('Aderzahl-0,9')}/>x4x0,9</div>
                  </div>
                </div>
            </div>
            <ConfirmationButton submit={this.submit} />
        </>
    }
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    cartWorkingItem: state.cartWorkingItem
})

export default connect(mapStateToProps, {setCombination})(NewCombination)