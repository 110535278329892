import types from '../type/types.js'

export const setType = type => ({
    type: types.SET_WIRE_SELECTION_TYPE,
    payload: type
  })
export const setModel = model => ({
    type: types.SET_WIRE_SELECTION_MODEL,
    payload: model
  })
export const setSuggestion = suggestion => ({
    type: types.SET_WIRE_SELECTION_SUGGESTION,
    payload: suggestion
  })

  export default {}
  