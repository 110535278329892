import React, {Component} from 'react'
import {connect} from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {setCombinationType} from '../../../action/combination'
import './WireUsage.scss'
import InfoBlock from '../InfoBlock/InfoBlock'

class WireUsage extends Component {

    constructor(props) {
        super(props)
        this.isCombinationActive = this.isCombinationActive.bind(this)
        this.setCombinationType = this.setCombinationType.bind(this)
    }

    isCombinationActive(type) {
        return this.props.cartWorkingItem && this.props.cartWorkingItem.combinationType && this.props.cartWorkingItem.combinationType === type
    }

    setCombinationType(type) {
        return () => {
            this.props.setCombinationType(type)
            this.props.done()
        }
    }

    render() {
        return <Container id="wire-usage">
        <Row>
            <Col className="selector-wrapper" xs={12} md={6}>
            <div className={`selector ${this.isCombinationActive('known') ? 'selected' : ''}`} onClick={this.setCombinationType('known')}>Ersatz für Kabel nach Dlk 1.01.106</div>
            <InfoBlock><div>Ersatz sind auch Teilstrecken.</div></InfoBlock>
            </Col>
            <Col className="selector-wrapper" xs={12} md={6}>
            <div className={`selector ${this.isCombinationActive('new') ? 'selected' : ''}`} onClick={this.setCombinationType('new')}>Neue Anlage</div>
                </Col>
        </Row>
    </Container>
    }
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    cartWorkingItem: state.cartWorkingItem
})

export default connect(mapStateToProps, {setCombinationType})(WireUsage)