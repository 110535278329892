import React, { Component } from "react";
import "./Sleeve.scss";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import InfoBlock from "../InfoBlock/InfoBlock";
import { connect } from "react-redux";
import { setSleeve } from "../../../action/sleeve";

class Sleeve extends Component {
  constructor(props) {
    super(props);
    this.state = props.sleeve
      ? { ...props.sleeve }
      : { inline: "", aufteil: "" };
    this.setSleeveAmount = this.setSleeveAmount.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  setSleeveAmount() {
    this.props.setSleeve(this.state);
    this.props.done();
  }

  updateValue(key) {
    return (e) => {
      const value = parseInt(e.target.value);
      this.setState({ ...this.state, [key]: value });
    };
  }

  render() {
    return (
      <>
        <div id="sleeve">
          <div className="input-element-group-wrapper">
            <div className="input-element-group">
              <div className="input-element">
                <div className="headline">
                  Verbindungsmuffen-Sets (je Kabel):
                </div>
                <input
                  min="0"
                  type="number"
                  value={this.state["inline"]}
                  placeholder="z. B. 3"
                  onChange={this.updateValue("inline")}
                />
              </div>
            </div>
            {this.props.combinationType === "known" &&
            this.props.wireSelection &&
            this.props.wireSelection.suggestion &&
            this.props.wireSelection?.suggestion?.rating?.wireAmount > 1 ? (
              <>
                <div className="input-element-group">
                  <div className="input-element">
                    <div className="headline">Abzweigmuffen-Sets:</div>
                    <input
                      min="0"
                      type="number"
                      value={this.state["aufteil"]}
                      placeholder="z. B. 3"
                      onChange={this.updateValue("aufteil")}
                    />
                  </div>
                </div>
                <InfoBlock>
                  <div>
                    Die gewünschte Aderkombination ist nicht (optimal) mit einem
                    einzelnen Kabel ersetzbar.
                  </div>
                  <div>
                    Zur Verbindung auf freier Strecke stellen wir Ihnen ein
                    individuelles Abzweigmuffen-Set (Y-Muffe) zusammen.
                  </div>
                </InfoBlock>
              </>
            ) : (
              ""
            )}
            <InfoBlock>
              <div>Aderverbindungsmaterial nicht im Lieferumfang</div>
            </InfoBlock>
          </div>
        </div>
        <ConfirmationButton submit={this.setSleeveAmount} />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  sleeve: state.cartWorkingItem.sleeve,
  wireSelection: state.cartWorkingItem.wireSelection,
  combinationType: state.cartWorkingItem.combinationType,
});

export default connect(mapStateToProps, { setSleeve })(Sleeve);
