import types from '../type/types.js'

export const persistToCart = () => ({
    type: types.PERSIST_TO_CART,
    payload: null
})
export const removeFromCart = index => ({
    type: types.REMOVE_FROM_CART,
    payload: index
})

export const editCartItem = index => ({
    type: types.EDIT_CART_ITEM,
    payload: index
})

export default {}