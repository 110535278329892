const PERSIST_TO_CART = 'persist_to_cart'
const REMOVE_FROM_CART = 'remove_from_cart'
const EDIT_CART_ITEM = 'edit_cart_item'

const SET_SLEEVE = 'set_sleeve'

const SET_WIRE_LENGTH = 'set_wire_length'

const SET_COMBINATION = 'set_combination'
const SET_COMBINATION_TYPE = 'set_combination_type'

const SET_WIRE_SELECTION_TYPE = 'set_wire_selection_type'
const SET_WIRE_SELECTION_MODEL = 'set_wire_selection_model'
const SET_WIRE_SELECTION_SUGGESTION = 'set_wire_selection_suggestion'

const RESET_EVERYTHING = 'reset_everything'

const SET_STATE = 'set_state'

export default { PERSIST_TO_CART, REMOVE_FROM_CART, EDIT_CART_ITEM, SET_SLEEVE, SET_WIRE_LENGTH, SET_COMBINATION, SET_COMBINATION_TYPE, SET_WIRE_SELECTION_TYPE, SET_WIRE_SELECTION_MODEL, SET_WIRE_SELECTION_SUGGESTION, RESET_EVERYTHING, SET_STATE }