import React, { Component } from "react";
import Item from "./Item/Item";
import "./Table.scss";
import utility from "../../../../../utility";

class Table extends Component {
  render() {
    return (
      <div id="SuggestionTable">
        <div className="header">
          <div className="item">Anzahl Kabel</div>
          <div className="item">Reserve Paare 1,4er</div>
          {this.props.combinationType === "known" && (
            <div className="item">Reserve Paare 1,2er</div>
          )}
          <div className="item">Reserve Paare 0,9er</div>
          <div className="item sumOverlapPair">Summe Reserve Paare</div>
          <div className="item"></div>
        </div>
        {this.props.suggestions.map((suggestion) => (
          <Item
            combinationType={this.props.combinationType}
            key={utility.getUUID()}
            suggestion={suggestion}
            done={this.props.done}
          />
        ))}
      </div>
    );
  }
}

export default Table;
