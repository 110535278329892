import React, {Component} from 'react'
import './Type.scss'
import {connect} from 'react-redux'
import {setType} from '../../../../action/wireSelection'

class Type extends Component {

    constructor(props) {
        super(props)
        this.select = this.select.bind(this)
    }

    select() {
        this.props.setType(this.props.type)
    }

    render() {
        return <div className="wireselection-type">                    
                    <label htmlFor={this.props.type}><input type="radio" id={this.props.type} name="wireselection-type-radio-group" value={this.props.type} onChange={this.select} checked={this.props.type === this.props.selectedType}/>{this.props.type}</label>
                </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    selectedType: state.cartWorkingItem.wireSelection && state.cartWorkingItem.wireSelection.type
})

export default connect(mapStateToProps, {setType})(Type)