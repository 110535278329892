import React, {Component} from 'react'
import './KnownCombination.scss'
import utility from '../../../utility'
import Result from './Result/Result'
import InfoBlock from '../InfoBlock/InfoBlock'

class KnownCombination extends Component {

    constructor(props) {
        super(props)
        this.state = {searchValue: '', results: []}
        this.done = this.done.bind(this)
        this.instantSearch = this.instantSearch.bind(this)
    }

    instantSearch(e) {
        const value = e.target.value
        if(value.length > 0) {
            this.setState({...this.state, searchValue: e.target.value})
            fetch(`${utility.backendDomain}/search/known-combination`, {
                method: 'post',
                body: JSON.stringify({value}),
                headers: { 'Content-Type': 'application/json' },
            })
            .then(payload => payload.json())
            .then(body => {
                if(body.status && Array.isArray(body.data)) {
                    this.setState({...this.state, results: body.data})
                } else {
                    this.setState({...this.state, results: []})
                }
            })
            .catch(err => console.log(err))
        } else {
            this.setState({...this.state, results: [], searchValue: ''})
        }
    }

    done() {
        this.props.done()
    }

    render() {
        return <div id="knowncombination">
                    <div className="input-wrapper">
                        <div className="input-headline">Zu ersetzendes Kabel (Paarzahl eingeben – gesamt oder eines beliebigen Leiterdurchmessers):</div>
                        <div className="input-element"><input value={this.state.searchValue}
                            placeholder="z.B.: 12, 8 oder 4 für 12 ́ ́ (-/8/4)" onChange={this.instantSearch} /></div>
                    </div>
                    <div className="results">
                        {this.state.results.map((result) => <Result key={utility.getUUID()} data={result} done={this.done} isSelected={result.isSelected}/>)}
                    </div>
                    <InfoBlock>
                        <div className="order-table">
                            <div className="head">Reihenfolge der Paar-Angaben nach Dlk 1.01.106 (nach Leiterdurchmesser):</div>
                            <div className="content-row">
                            <div className="key">2-stellig:</div>
                            <div className="value">(1,4/0,9)</div>
                            </div>
                            <div className="content-row">
                            <div className="key">3-stellig:</div>
                            <div className="value">(1,4/1,2/0,9)</div>
                            </div>
                            <div className="content-row">
                            <div className="key">4-stellig:</div>
                            <div className="value">(1,4/Kx/1,2/0,9)</div>
                            </div>
                        </div>
                    </InfoBlock>
            </div>
    }
}

export default KnownCombination