import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import "./FAQModal.scss";
import Redfaktor16 from "../../assets/image/Redfaktor-16,66-Hz.png";
import Redfaktor50 from "../../assets/image/Redfaktor-50-Hz.png";
import Kalbelauswahl1 from "../../assets/image/Kalbelauswahl1.png";
import Kalbelauswahl2 from "../../assets/image/Kalbelauswahl2.png";
import AttachedImages from "../Home/WireSelection/AttachedImages/AttachedImages";

class FAQModal extends Component {
  constructor(props) {
    super(props);
    this.state = { triggerContent: props.triggerContent };
    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
  }

  show() {
    this.setState({ ...this.state, visible: true });
  }

  close() {
    this.setState({ ...this.state, visible: false });
  }

  render() {
    return (
      <>
        <div onClick={this.show} className="faqmodal-trigger">
          {this.state.triggerContent}
        </div>

        <Modal
          id="FAQModal"
          show={this.state.visible}
          onHide={this.close}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="faq-table">
              <div className="row">
                <div className="col-4 headline">Frage:</div>
                <div className="col-8 headline">Antwort:</div>
                <div className="col-12 col-md-4 question">
                  Beim Neubau gibt es keine 1,2er Adern mehr. Was soll ich tun?
                </div>
                <div className="col-12 col-md-8 answer">
                  Alte 1,2er Adern werden an neuen 1,4er angeschlossen. Das ist
                  bei der Berechnung schon berücksichtigt.
                </div>
                <div className="col-12 col-md-4 question">
                  Es gibt keine Koaxialelemente mehr. Was soll ich tun?
                </div>
                <div className="col-12 col-md-8 answer">
                  Die Koaxialelemente werden nicht mehr beschaltet und entfallen
                  ersatzlos.
                </div>
              </div>
              <div className="section-headline">Kabelauswahl-Hilfe</div>
              <AttachedImages images={[Kalbelauswahl1, Kalbelauswahl2]} />
              <div className="section-headline">Reduktionsfaktor-Kurven</div>
              <AttachedImages images={[Redfaktor16, Redfaktor50]} />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default FAQModal;
