import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import "./DataProtection.scss";

class DataProtection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="dataprotection">
        <Container>
          <div className="headline">DATENSCHUTZERKLÄRUNG</div>
          <div className="text">
            Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
            EU-Datenschutzgrundverordnung (DSGVO), ist:
          </div>

          <div className="text">Bayerische Kabelwerke AG</div>
          <div className="text">Otto-Schrimpff-Str. 2</div>
          <div className="text">91154 Roth</div>

          <div className="text">kabel@bayka.de</div>
          <div className="text">Tel. +49 (0)9171 806111</div>
          <div className="subheadline">Ihre Betroffenenrechte</div>
          <div className="text">
            Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
            können Sie jederzeit folgende Rechte ausüben:
          </div>
          <div className="list">
            <ul>
              <li>
                Auskunft über Ihre bei uns gespeicherten Daten und deren
                Verarbeitung (Art. 15 DSGVO),
              </li>
              <li>
                Berichtigung unrichtiger personenbezogener Daten (Art. 16
                DSGVO),
              </li>
              <li>
                Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
              </li>
              <li>
                Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
                aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art.
                18 DSGVO),
              </li>
              <li>
                Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
                DSGVO) und
              </li>
              <li>
                Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
                eingewilligt haben oder einen Vertrag mit uns abgeschlossen
                haben (Art. 20 DSGVO).
              </li>
            </ul>
          </div>
          <div className="text">
            Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
            jederzeit mit Wirkung für die Zukunft widerrufen.
          </div>

          <div className="text">
            Sie können sich jederzeit mit einer Beschwerde an eine
            Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde
            des Bundeslands Ihres Wohnsitzes oder an die für uns als
            verantwortliche Stelle zuständige Behörde.
          </div>

          <div className="text">
            Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
            mit Anschrift finden Sie unter:
          </div>
          <div className="text">
            <a
              href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
              target="_blank" rel="noopener noreferrer"
            >
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
            </a>
          </div>
          <div className="subheadline">Cookies</div>
          <div className="seperator-headline">
            Art und Zweck der Verarbeitung:
          </div>
          <div className="text">
            Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.
            Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem
            Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn
            Sie unsere Webseite besuchen.
          </div>

          <div className="text">
            Hierdurch erhalten wir bestimmte Daten wie z. B. IP-Adresse,
            verwendeter Browser und Betriebssystem.
          </div>

          <div className="text">
            Cookies können nicht verwendet werden, um Programme zu starten oder
            Viren auf einen Computer zu übertragen. Anhand der in Cookies
            enthaltenen Informationen können wir Ihnen die Navigation
            erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.
          </div>

          <div className="text">
            In keinem Fall werden die von uns erfassten Daten an Dritte
            weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit
            personenbezogenen Daten hergestellt.
          </div>

          <div className="text">
            Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
            betrachten. Internet-Browser sind regelmäßig so eingestellt, dass
            sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung
            von Cookies jederzeit über die Einstellungen Ihres Browsers
            deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres
            Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern
            können. Bitte beachten Sie, dass einzelne Funktionen unserer Website
            möglicherweise nicht funktionieren, wenn Sie die Verwendung von
            Cookies deaktiviert haben.
          </div>
          <div className="seperator-headline">
            Speicherdauer und eingesetzte Cookies:
          </div>
          <div className="text">
            Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die
            Verwendung von Cookies erlauben, können folgende Cookies auf unseren
            Webseiten zum Einsatz kommen:
          </div>

          <div className="text">
            Soweit diese Cookies (auch) personenbezogene Daten betreffen können,
            informieren wir Sie darüber in den folgenden Abschnitten.
          </div>

          <div className="text">
            Sie können über Ihre Browsereinstellungen einzelne Cookies oder den
            gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie
            Informationen und Anleitungen, wie diese Cookies gelöscht oder deren
            Speicherung vorab blockiert werden können. Je nach Anbieter Ihres
            Browsers finden Sie die notwendigen Informationen unter den
            nachfolgenden Links:
          </div>
          <div className="list">
            <ul>
              <li>
                Mozilla Firefox:{" "}
                <a
                  href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
                  target="_blank" rel="noopener noreferrer"
                >
                  https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
                </a>
              </li>
              <li>
                Internet Explorer:{" "}
                <a
                  href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
                  target="_blank" rel="noopener noreferrer"
                >
                  https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
              </li>
              <li>
                Google Chrome:{" "}
                <a
                  href="https://support.google.com/accounts/answer/61416?hl=de"
                  target="_blank" rel="noopener noreferrer"
                >
                  https://support.google.com/accounts/answer/61416?hl=de
                </a>
              </li>
              <li>
                Opera:{" "}
                <a href="http://www.opera.com/de/help" target="_blank" rel="noopener noreferrer">
                  http://www.opera.com/de/help
                </a>
              </li>
              <li>
                Safari:{" "}
                <a
                  href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE"
                  target="_blank" rel="noopener noreferrer"
                >
                  https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE
                </a>
              </li>
            </ul>
          </div>
          <div className="subheadline">Newsletter</div>
          <div className="seperator-headline">
            Art und Zweck der Verarbeitung:
          </div>
          <div className="text">
            Ihre Daten werden ausschließlich dazu verwendet, Ihnen den
            abonnierten Newsletter per E-Mail zuzustellen. Die Angabe Ihres
            Namens erfolgt, um Sie im Newsletter persönlich ansprechen zu können
            und ggf. zu identifizieren, falls Sie von Ihren Rechten als
            Betroffener Gebrauch machen wollen.
          </div>

          <div className="text">
            Für den Empfang des Newsletters ist die Angabe Ihrer E-Mail-Adresse
            ausreichend. Bei der Anmeldung zum Bezug unseres Newsletters werden
            die von Ihnen angegebenen Daten ausschließlich für diesen Zweck
            verwendet. Abonnenten können auch über Umstände per E-Mail
            informiert werden, die für den Dienst oder die Registrierung
            relevant sind (bspw. Änderungen des Newsletterangebots oder
            technische Gegebenheiten).
          </div>

          <div className="text">
            Für eine wirksame Registrierung benötigen wir eine valide
            E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich
            durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das
            „Double-opt-in“-Verfahren ein. Hierzu protokollieren wir die
            Bestellung des Newsletters, den Versand einer Bestätigungsmail und
            den Eingang der hiermit angeforderten Antwort. Weitere Daten werden
            nicht erhoben. Die Daten werden ausschließlich für den
            Newsletterversand verwendet und nicht an Dritte weitergegeben.
          </div>
          <div className="seperator-headline">Rechtsgrundlage:</div>
          <div className="text">
            Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs.
            1 lit. a DSGVO), übersenden wir Ihnen regelmäßig unseren Newsletter
            bzw. vergleichbare Informationen per E-Mail an Ihre angegebene
            E-Mail-Adresse.
          </div>

          <div className="text">
            Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
            Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung
            für die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein
            entsprechender Link. Außerdem können Sie sich jederzeit auch direkt
            auf dieser Website abmelden oder uns Ihren Widerruf über die am Ende
            dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.
          </div>
          <div className="seperator-headline">Empfänger:</div>
          <div className="text">
            Empfänger der Daten sind ggf. Auftragsverarbeiter.
          </div>
          <div className="seperator-headline">Speicherdauer:</div>
          <div className="text">
            Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die
            entsprechende Einwilligung vorliegt. Danach werden sie gelöscht.
          </div>
          <div className="seperator-headline">
            Bereitstellung vorgeschrieben oder erforderlich:
          </div>
          <div className="text">
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
            allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung
            können wir Ihnen unseren Newsletter leider nicht zusenden.
          </div>
          <div className="subheadline">Kontaktformular</div>
          <div className="seperator-headline">Art und Zweck der Verarbeitung:</div>
<div className="text">Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</div>
          <div className="seperator-headline">Rechtsgrundlage:</div>
          <div className="text">Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</div>
<div className="text">Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</div>
<div className="text">Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</div>
<div className="seperator-headline">Empfänger:</div>
<div className="text">Empfänger der Daten sind ggf. Auftragsverarbeiter.</div>
<div className="seperator-headline">Speicherdauer:</div>
<div className="text">Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</div>
<div className="text">Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen.</div>
<div className="seperator-headline">Bereitstellung vorgeschrieben oder erforderlich:</div>
<div className="text">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</div>
<div className="headline">Verwendung von Google Analytics</div>
<div className="seperator-headline">Art und Zweck der Verarbeitung:</div>
<div className="text">Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</div>
<div className="text">Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Website und in der Zusammenstellung von Reports über Aktivitäten auf der Website. Auf Grundlage der Nutzung der Website und des Internets sollen dann weitere verbundene Dienstleistungen erbracht werden.</div>
<div className="seperator-headline">Rechtsgrundlage:</div>
<div className="text">Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</div>
<div className="seperator-headline">Empfänger:</div>
<div className="text">Empfänger der Daten ist Google als Auftragsverarbeiter. Hierfür haben wir mit Google den entsprechenden Auftragsverarbeitungsvertrag abgeschlossen.</div>
<div className="seperator-headline">Speicherdauer:</div>
<div className="text">Die Löschung der Daten erfolgt, sobald diese für unsere Aufzeichnungszwecke nicht mehr erforderlich sind.</div>
<div className="seperator-headline">Drittlandtransfer:</div>
<div className="text">Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.</div>
<div className="seperator-headline">Bereitstellung vorgeschrieben oder erforderlich:</div>
<div className="text">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der Website kommen.</div>
<div className="seperator-headline">Widerruf der Einwilligung:</div>
<div className="text">Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: Browser Add On zur Deaktivierung von Google Analytics.</div>
<div className="text">Zusätzlich oder als Alternative zum Browser-Add-On können Sie das Tracking durch Google Analytics auf unseren Seiten unterbinden, indem Sie diesen Link anklicken. Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert, so lange das Cookie in Ihrem Browser installiert bleibt.</div>
<div className="seperator-headline">Profiling:</div>
<div className="text">Mit Hilfe des Tracking-Tools Google Analytics kann das Verhalten der Besucher der Webseite bewertet und die Interessen analysiert werden. Hierzu erstellen wir ein pseudonymes Nutzerprofil.</div>
<div className="subheadline">Verwendung von Scriptbibliotheken (Google Webfonts)</div>
<div className="seperator-headline">Art und Zweck der Verarbeitung:</div>
<div className="text">Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</div>
<div className="text">Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">https://www.google.com/policies/privacy/</a></div>
<div className="seperator-headline">Rechtsgrundlage:</div>
<div className="text">Rechtsgrundlage für die Einbindung von Google Webfonts und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</div>
<div className="seperator-headline">Empfänger:</div>
<div className="text">Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass der Betreiber in diesem Fall Google Daten erhebt.</div>
<div className="seperator-headline">Speicherdauer:</div>
<div className="text">Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Webfonts.</div>
<div className="text">Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">https://developers.google.com/fonts/faqs</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">https://www.google.com/policies/privacy/</a>.</div>
<div className="seperator-headline">Drittlandtransfer:</div>
<div className="text">Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.</div>
<div className="seperator-headline">Bereitstellung vorgeschrieben oder erforderlich:</div>
<div className="text">Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch vertraglich vorgeschrieben. Allerdings kann ggfs. die korrekte Darstellung der Inhalte durch Standardschriften nicht möglich sein.</div>
<div className="seperator-headline">Widerruf der Einwilligung:</div>
<div className="text">Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache JavaScript verwendet. Sie können der Datenverarbeitung daher widersprechen, indem Sie die Ausführung von JavaScript in Ihrem Browser deaktivieren oder einen JavaScript-Blocker installieren. Bitte beachten Sie, dass es hierdurch zu Funktionseinschränkungen auf der Website kommen kann.</div>
<div className="headline">Eingebettete YouTube-Videos</div>
<div className="seperator-headline">Art und Zweck der Verarbeitung:</div>
<div className="text">Auf einigen unserer Webseiten betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (nachfolgend „YouTube“). Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.</div>
<div className="text">Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.</div>
<div className="text">Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre (https://policies.google.com/privacy). Google verarbeitet Ihre Daten in den USA und hat sich dem EU-US Privacy Shield unterworfen https://www.privacyshield.gov/EU-US-Framework</div>
<div className="seperator-headline">Rechtsgrundlage:</div>
<div className="text">Rechtsgrundlage für die Einbindung von YouTube und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</div>
<div className="seperator-headline">Empfänger:</div>
<div className="text">Der Aufruf von YouTube löst automatisch eine Verbindung zu Google aus.</div>
<div className="seperator-headline">Speicherdauer und Widerruf der Einwilligung:</div>
<div className="text">Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von YouTube-Videos mit keinen solchen Cookies rechnen müssen. YouTube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren.</div>
<div className="text">Weitere Informationen zum Datenschutz bei „YouTube“ finden Sie in der Datenschutzerklärung des Anbieters unter: <a href="https://www.google.de/intl/de/policies/privacy/" target="_blank" rel="noopener noreferrer">https://www.google.de/intl/de/policies/privacy/</a></div>
<div className="seperator-headline">Drittlandtransfer:</div>
<div className="text">Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.</div>
<div className="seperator-headline">Bereitstellung vorgeschrieben oder erforderlich:</div>
<div className="text">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der Website kommen.</div>
<div className="headline">SSL-Verschlüsselung</div>
<div className="text">Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</div>
<div className="headline">Eingesetzte Auftragsverarbeiter</div>
<div className="text">Folgende Organisationen, Unternehmen bzw. Personen wurden vom Betreiber dieser Website mit der Verarbeitung von Daten beauftragt:</div>
<div className="text">
LionKeen GmbH & Co. KG<br/>
CleverReach GmbH & Co. KG<br/>
Hazelnut | büro für design & entwicklung
</div>
<div className="headline">Änderung unserer Datenschutzbestimmungen</div>
<div className="text">Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</div>

<div className="headline">Fragen an den Datenschutzbeauftragten</div>
<div className="text">Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</div>
<div className="text">
Bayerische Kabelwerke AG<br/>
Otto-Schrimpff-Str. 2<br/>
91154 Roth<br/>
E-Mail: datenschutz@bayka.de
</div>
<div className="text"><i>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für externe Datenschutzbeauftragte (Version #2019-04-10).</i></div>




        </Container>
      </div>
    );
  }
}

export default DataProtection;
