import React, { Component } from "react";
import "./Header.scss";
import logo from "../../assets/image/logo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FAQModal from '../FAQModal/FAQModal'

class Header extends Component {
  render() {
    return (
      <div id="header" className={this.props.iframe === true? 'iframe-view' : ''}>
        <Container fluid>
          <Row>
            {this.props.iframe === false ?
            <Col xs={6} className="main-menu">
              <div>
                <a href="/">
                  <img src={logo} alt="Bayka" />
                </a>
              </div>
            </Col>
             : ''}
            <Col xs={this.props.iframe === true ? 12 : 6} className="secondary-menu">
              <div>
                <FAQModal triggerContent={'FAQ'}/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Header;
