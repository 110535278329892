import React, { Component } from 'react'
import InfoBlock from '../../InfoBlock/InfoBlock'
import utility from '../../../../utility'
import Table from './Table/Table'
import './Suggestion.scss'
import { connect } from 'react-redux'
import { setSuggestion } from '../../../../action/wireSelection'
import loadingGif from '../../../../assets/image/loading.gif'

class Suggestion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
      loading: false,
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const result = await fetch(`${utility.backendDomain}/search/suggestion`, {
      method: 'post',
      body: JSON.stringify({
        wireCombination: this.props.combination,
        model: this.props.wireSelection.model,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
    const body = await result.json()
    console.log(body)
    this.setState({ loading: false })
    if (body.status && body.suggestions) {
      if (body.suggestions.length > 0) {
        if (body.suggestions[0].rating.wireAmount === 1) {
          this.props.setSuggestion(body.suggestions[0])
          this.props.done()
        } else {
          this.setState({ suggestions: body.suggestions })
        }
      } else {
        this.props.setSuggestion({})
        this.props.done()
      }
    } else {
      console.log('/search/suggestion - failed')
    }
  }

  render() {
    if (this.state.suggestions.length > 0) {
      return (
        <div id="suggestion">
          <InfoBlock type="attention">
            <div>
              Die gewünschte Aderkombination ist nicht (optimal) mit einem einzelnen Kabel ersetzbar.
              <br />
              Bitte wählen Sie aus den bestmöglichen Kombinationen:
            </div>
          </InfoBlock>
          <Table combinationType={this.props.combinationType} key={utility.getUUID()} suggestions={this.state.suggestions} done={this.props.done} />
        </div>
      )
    } else if (this.state.loading) {
      return (
        <div style={{ marginLeft: '10px', paddingTop: '15px', paddingBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>Daten werden geladen...</div>
          <img style={{ height: '30px', marginTop: '15px' }} src={loadingGif} alt="loading" />
        </div>
      )
    } else {
      return ''
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  combination: state.cartWorkingItem.combination,
  combinationType: state.cartWorkingItem.combinationType,
  wireSelection: state.cartWorkingItem.wireSelection,
})

export default connect(mapStateToProps, { setSuggestion })(Suggestion)
